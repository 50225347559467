/* eslint-disable no-unused-expressions */
import GtmTracker, { transformPayload } from '../lib/tracking/trackGtm';
import { default as uaTrack }  from '../lib/tracking/track';

const PAGE_LOCATION = 'home';

const trackAiButtonClick = () => {
  const gtmPayload = {
    eventLabel: 'Start Learning - Ai',
    eventCategory: PAGE_LOCATION,
    eventAction: 'button_clicked',
  };
  new GtmTracker().track(transformPayload(gtmPayload));

  const uaPayload = {
    pageLocation: PAGE_LOCATION,
    ctaType: 'primary',
    label: 'Start Learning - Ai'
  };

  uaTrack('cta_clicked', uaPayload);
}

const trackCMPButtonClick = () => {
  const gtmPayload = {
    eventLabel: 'Join Career Marketplace',
    eventCategory: PAGE_LOCATION,
    eventAction: 'button_clicked',
  };
  new GtmTracker().track(transformPayload(gtmPayload));

  const uaPayload = {
    pageLocation: PAGE_LOCATION,
    ctaType: 'primary',
    label: 'Join Career Marketplace'
  };

  uaTrack('cta_clicked', uaPayload);
}

const trackWorkcomLinkClick = () => {
  const payload = {
    eventLabel: 'Learn More - Work.com',
    eventCategory: PAGE_LOCATION,
    eventAction: 'link_clicked',
  };
  new GtmTracker().track(transformPayload(payload));
};

const trackCertificationClick = () => {
  const payload = {
    eventLabel: 'explore_other_certifications',
    eventCategory: PAGE_LOCATION,
    eventAction: 'button_clicked',
  };
  new GtmTracker().track(transformPayload(payload));
};

const trackCareerPathClicked = (clickEvent) => {
  const payload = {
    eventAction: 'career_path_clicked',
    eventLabel: clickEvent.currentTarget.getAttribute('data-tracking'),
    eventCategory: 'career_path',
  };
  new GtmTracker().track(transformPayload(payload));
};

const trackLoginLinkClick = (clickEvent) => {
  const uaPayload = {
    pageLocation: PAGE_LOCATION,
    ctaType: 'login_link',
    page_location: window.location.pathname,
    lang: window.I18n?.locale,
    label: clickEvent?.currentTarget?.textContent.replace(/\n/g, '')
  };

  uaTrack('cta_clicked', uaPayload);
}

document.addEventListener('DOMContentLoaded', () => {
  const certificationButton = document.getElementById('certification-btn');
  const careerHeadshots = document.getElementsByClassName(
    'th-careers__headshot',
  );
  const workcomLink = document.getElementById('workcom-link');
  const aiButton = document.getElementById('ai-button');
  const cmpButton = document.getElementById('cmp-promo-button');
  const loginLink = document.getElementById('login_link');
  loginLink && loginLink.addEventListener('click', trackLoginLinkClick);
  aiButton && aiButton.addEventListener('click', trackAiButtonClick);
  cmpButton && cmpButton.addEventListener('click', trackCMPButtonClick);
  certificationButton.addEventListener('click', trackCertificationClick);
  workcomLink && workcomLink.addEventListener('click', trackWorkcomLinkClick);
  careerHeadshots &&
    Array.from(careerHeadshots).forEach((h) => {
      h.getElementsByTagName('a')[0].addEventListener(
        'click',
        trackCareerPathClicked,
      );
    });
});
